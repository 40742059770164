import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="4d101cc5d1d88286abc018da712eb7aeb3d73691"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/la-diarrhee/"> Stress et diarrhée </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils pour le traitement des symptomes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                  Causes de la diarrhée
                </a>
              </li>
              <li className="parent-active">
                <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                  Stress et diarrhée
                </a>
                <ul>
                  <li className="active first">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                      Emploi du temps chargé
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/">
                      Faire face aux examens
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                  Infection virale ou bactérienne
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                  La diarrhée pendant les règles
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                  La diarrhée chronique
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                  Consulter un médecin?
                </a>
              </li>
              <li className="last">
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                  Des habitudes alimentaires saines
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/">
                      Allergies alimentaires{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                      Intoxication alimentaire
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                      Recettes Santé
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            {/*<div className="widget">
              <div className="inner">
                <h4>Diagnostic du syndrome du côlon irritable</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_stress_durchfall.jpeg"
                    width="170"
                    height="102"
                    alt="Diagnostic du syndrome du côlon irritable"
                  />
                </div>
                <p>
                  Ici vous trouverez des informations sur les causes et le
                  traitement du côlon irritable.
                </p>
                <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                  Plus d'info
                </a>
              </div>
            </div>*/}
          </div>
          <div className="page nine columns page-21">
            <h1>Stress à cause de la charge de travail</h1>
            <div className="img">
              <img
                src="/assets/files/pages/stress.jpeg"
                width="701"
                height="289"
                alt="Emploi du temps chargé"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Ne laissez pas la diarrhée prendre les commandes</h2>
              </div>
              <div className="nine columns">
                <p>
                  Chacun peut être stressé au travail de temps à autre, mais une
                  diarrhée liée au travail montre que celui-ci vous affecte
                  physiquement et mentalement. Une diarrhée qui survient au
                  travail peut non seulement être particulièrement gênante, mais
                  elle peut aussi vous fatiguer énormément et vous faire vous
                  sentir encore moins capable de faire face à votre journée.
                </p>
              </div>
            </div>
            <h4>Comment survivre au stress du travail&nbsp;?</h4>
            <p>
              Voici quelques conseils importants qui vous aideront à surmonter
              le stress au travail. Le stress peut être traité avec succès de
              différentes manières, et certains des conseils ci-dessous
              fonctionneront mieux pour vous que d’autres. Il est important que
              vous trouviez une manière de gérer votre stress d’une manière
              saine.
            </p>
            <ul>
              <li>
                <strong>Prenez du temps pour vous</strong>.&nbsp;Pas le temps de
                s’arrêter pour déjeuner&nbsp;? Essayez de faire une pause un peu
                plus tard – normalement, on est plus performant après un peu de
                repos.
              </li>
              <li>
                <strong>
                  Veillez à{" "}
                  <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                    une alimentation équilibrée
                  </a>
                </strong>
                .&nbsp;Les produits complets et les légumes verts à feuilles
                sont riches en vitamines B, qui favorisent la relaxation – le
                pain complet et les collations à base d’avoine sont donc
                recommandés. Vous trouverez des recettes pour une alimentation
                équilibrée sur notre{" "}
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                  page de recettes
                </a>
                .
              </li>
              <li>
                <strong>Prenez de l’assurance</strong>.&nbsp;Apprenez à dire non
                sans blesser ni offenser les autres. Si vous vous trouvez dans
                une situation de confrontation potentielle, essayez de rester
                calme et de trouver une solution acceptable pour chacun.
              </li>
              <li>
                <strong>Apprenez à gérer votre temps plus efficacement</strong>
                .&nbsp;Fixez des priorités à votre journée en mettant en haut de
                la liste les tâches désagréables pour pouvoir en être libéré au
                lieu de les avoir en tête toute la journée.
              </li>
              <li>
                <strong>Acceptez ce que vous ne pouvez pas changer</strong>
                .&nbsp;Essayez d’améliorer les situations stressantes&nbsp;;
                toutefois, lorsque ce n’est pas possible, faites de votre mieux
                pour accepter les choses telles qu’elles sont.
              </li>
              <li>
                <strong>Essayez de voir les choses différemment</strong>
                .&nbsp;Si quelque chose vous ennuie, essayez d’adopter un autre
                point de vue, moins stressant. Parlez-en à quelqu’un avant que
                cela ne prenne des proportions excessives.
              </li>
              <li>
                <strong>Riez&nbsp;!</strong>&nbsp;Essayez de voir le côté
                comique de la situation. Le rire réduit le stress et stimule le
                système immunitaire, souvent affaibli par le stress.
              </li>
              <li>
                <strong>
                  Évitez d’être dépendant de l’alcool, de la nicotine et de la
                  caféine
                </strong>
                .&nbsp;À long terme, cela aggravera votre problème. La caféine,
                par exemple, est un stimulant qui peut provoquer du stress.
              </li>
              <li>
                <strong>Bougez</strong>.&nbsp;L’activité physique annule les
                changements biochimiques et physiques provoqués par le stress
                dans votre corps.
              </li>
              <li>
                <strong>Apprenez des techniques de relaxation</strong>.&nbsp;Les
                bonnes techniques de relaxation pour une meilleure gestion du
                stress incluent des exercices de respiration, des massages et
                diverses thérapies complémentaires.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
